import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSax from "vuesax";
import moment from "moment";
import store from "./store";
import "vuesax/dist/vuesax.css";
import "./index.scss";
import "boxicons/css/boxicons.min.css";
import "@/services/UserService";
import "windi.css";
import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import VueNativeSock from "vue-native-websocket";
import { patchHwid } from "@/services/ApiService";
Vue.config.productionTip = false;
Vue.config.devtools = true;
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.use(VueSplide);
Vue.use(VueNativeSock, "ws://localhost:16047", {
  format: "json",
  reconnection: true,
});

Vue.use(VueSax, {
  colors: {
    primary: "#5b3cc4",
    success: "rgb(23, 201, 100)",
    danger: "rgb(242, 19, 93)",
    warning: "rgb(255, 130, 0)",
    dark: "rgb(36, 33, 69)",
  },
});

Vue.filter("formatTimestamp", function (value) {
  if (value) {
    return moment.unix(value).format("MM/DD/YY hh:mm");
  }
});

new Vue({
  router,
  render: (h) => h(App),
  store,
  created() {
    this.$options.sockets.onmessage = ({ data }) => {
      data = JSON.parse(data);
      if (data.type === "hwid") {
        patchHwid(data.data);
      }
      if (data.type === "version") {
        this.$store.state.version = data.data;
      }
    };
  },
}).$mount("#app");
