const Token = () => {
  const getToken = () => {
    return localStorage.getItem("token");
  };

  const setToken = (token) => {
    return localStorage.setItem("token", token);
  };

  const clearToken = () => {
    return localStorage.removeItem("token");
  };

  const parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  return {
    getToken,
    setToken,
    clearToken,
    parseJwt,
  };
};

export default Token();
