import TokenService from "@/services/TokenService";
import router from "@/router";

const axios = require("axios").default;

const Http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

Http.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      TokenService.clearToken();
      router.push("/login");
    }
    return response;
  },
  function (error) {
    if (
      error &&
      error.message &&
      error.message === "Request failed with status code 401"
    ) {
      TokenService.clearToken();
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

Http.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${TokenService.getToken()}`;

  return config;
});

export default Http;
