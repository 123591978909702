<template>
  <div class="flex items-center justify-center h-full">
    <div class="flex items-center justify-center gap-4 flex-col">
      <h1 class="text-2xl font-bold">Login</h1>

      <vs-input block v-model="username" placeholder="User name">
        <template #icon>
          <i class="bx bx-user" />
        </template>
      </vs-input>

      <vs-input block type="password" v-model="password" placeholder="Password">
        <template #icon>
          <i class="bx bx-lock-open-alt" />
        </template>
      </vs-input>

      <vue-hcaptcha
        ref="captcha"
        sitekey="99cde083-e013-4c11-aa2a-f715a1a27fbb"
        @verify="captcha = $event"
        @expired="captcha = undefined"
        theme="dark"
      ></vue-hcaptcha>

      <vs-button block @click="onLoginButtonClick"> Login</vs-button>

      <div class="text-xs">
        <span> New Here? </span>
        <router-link to="/register" class="ml-1.5 text-success hover:underline">
          Create New Account
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/HttpService";
import token from "@/services/TokenService";
import { syncUser } from "@/services/UserService";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
  name: "Login",
  components: { VueHcaptcha },
  data() {
    return {
      username: undefined,
      password: undefined,
      captcha: undefined,
    };
  },
  methods: {
    onLoginButtonClick() {
      if (!this.captcha) {
        this.$vs.notification({
          position: "bottom-right",
          name: "Error",
          color: "danger",
          text: `Please complete captcha challenge.`,
        });
        return;
      }
      if (!this.username && !this.password) {
        this.$vs.notification({
          name: "Error",
          text: "Please fill all the fields",
          color: "danger",
          position: "bottom-right",
        });
        return;
      }

      this.$refs.captcha.reset();
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });

      api
        .Post("/session", {
          username: this.username,
          password: this.password,
          captcha: this.captcha,
        })
        .then((response) => {
          loading.close();
          token.setToken(response);
          this.$vs.notification({
            name: "Success",
            text: "Login Successful",
            color: "success",
            position: "bottom-right",
          });
          syncUser();
          this.$router.push("/home");
          setTimeout(() => {
            this.$router.push("/home");
          }, 1000);
        })
        .catch((error) => {
          loading.close();
          this.$vs.notification({
            name: "Error",
            text: error.response.data.message,
            color: "danger",
            position: "bottom-right",
          });
        });
    },
  },
};
</script>
