import HttpService from "@/services/HttpService";

const MEDIA_TYPES = ["IMAGE", "COVER", "EXECUTABLE"];

export const getMe = () => HttpService.Get("/user/@me");

export const getCommentsByProduct = (product, page = 1) =>
  HttpService.Get(`/comment/all/${product}?page=${page}`);

export const getProductsByStoreForStore = (id) =>
  HttpService.Get("/product/" + id + "/plan");

export const getAllGamesByAdmin = (page) => {
  return HttpService.Get("/game/admin/all?page=" + page);
};

export const controlSubscriptionByProduct = (product) => {
  return HttpService.Get("/subscription/product/" + product);
};

export const getAllUsersByAdmin = (page = 1, input) => {
  let url = `/user?page=${page}`;
  if (input) {
    url += `&input=${input}`;
  }

  return HttpService.Get(url);
};

export const getAllProducts = (
  page = 0,
  active = true,
  ready = true,
  deleted = false
) => {
  return HttpService.Get(
    `/product/all?page=${page}&active=${active}&ready=${ready}&deleted=${deleted}`
  );
};

export const getAllGames = (hidden = false) => {
  return HttpService.Get(`/game?hidden=${hidden}`);
};

export const getAllSubscriptions = (page = 1) => {
  return HttpService.Get("/subscription?page=" + page);
};

export const getProducts = (storeId, page = 1) => {
  return HttpService.Get(`/product/store/${storeId}?page=${page}`);
};

export const getImagesByProduct = (productId) => {
  return HttpService.Get(`/file/${productId}/images`);
};

export const getPlansByStore = (storeId, page = 1) => {
  return HttpService.Get(`/plan/store/${storeId}?page=${page}`);
};

export const getPlans = (page = 1, input, store, product, oldPlans = false) => {
  let url = `/plan?page=${page}&oldPlans=${oldPlans}`;
  if (store) url += `&store=${store}`;
  if (product) url += `&product=${product}`;
  if (input) url += `&input=${input}`;

  return HttpService.Get(url);
};

export const getWallets = (page = 1) => {
  return HttpService.Get("/wallet?page=" + page);
};

export const getTransactions = (page = 1) => {
  return HttpService.Get("/transaction?page=" + page);
};

/* MY REQUESTS */
export const getMyTransactions = (page = 1) => {
  return HttpService.Get("/transaction/@me?page=" + page);
};

export const getMySubscriptions = (page = 1) => {
  return HttpService.Get("/subscription/@me?page=" + page);
};

export const getMyStore = () => {
  return HttpService.Get("/store/@me");
};

/* GET BY XX REQUESTS */
export const getGameById = (id) => {
  return HttpService.Get("/game/" + id);
};

export const getPlanById = (plan) => {
  return HttpService.Get(`/plan/${plan}`);
};

export const getProductById = (productId) => {
  return HttpService.Get(`/product/${productId}`);
};

export const getProductByGame = (gameID) => {
  return HttpService.Get(`/product/game/${gameID}`);
};

export const getFileByProduct = (productID, type) => {
  return HttpService.Get(`/file/${productID}?${type ? `type=${type}` : ""}`);
};

export const getLoader = (productID) => {
  return HttpService.Get(`/file/product/${productID}`);
};

export const getPlansByProduct = (productID) => {
  return HttpService.Get(`/plan/product/${productID}`);
};

/* POST REQUESTS */
export const createComment = (product, data) => {
  return HttpService.Post("/comment/" + product, data);
};

export const createWallet = (amount) => {
  return HttpService.Post("/wallet", amount);
};

export const createPlan = (payload) => {
  return HttpService.Post("/plan", payload);
};

export const createGame = (data) => {
  return HttpService.Post("/game", data);
};

export const createStore = (store) => {
  return HttpService.Post("/store", store);
};

export const uploadFile = (id, type = "EXECUTABLE", file) => {
  if (!MEDIA_TYPES.includes(type)) throw new Error("Invalid type");

  return HttpService.Post(`/file/${id}?type=${type}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const createProduct = (storeID, product) => {
  return HttpService.Post("/product/store/" + storeID, product);
};

export const createSubscription = (plan) => {
  return HttpService.Post("/subscription/" + plan);
};

/* PATCH AND PUT REQUESTS */
export const reCreateGame = (id) => {
  return HttpService.Put("/game/recreate/" + id);
};

export const useWalletCode = (walletCode) => {
  return HttpService.Patch("/wallet/" + walletCode);
};

export const createPayment = ({ email, gsm, amount, name, surname }) => {
  return HttpService.Post("/payment", { email, gsm, amount, name, surname });
};

export const updateGame = (id, data) => {
  return HttpService.Put("/game/" + id, data);
};

export const updateStore = (store, slug) => {
  return HttpService.Put("/store/" + store, slug);
};

export const updatePlan = (plan, payload) => {
  return HttpService.Put(`/plan/${plan}`, payload);
};

export const updateProduct = (id, product) => {
  return HttpService.Put("/product/" + id, product);
};

export const activateProduct = (id) => {
  return HttpService.Put("/product/" + id + "/activate");
};

/* DELETE REQUESTS */
export const deleteGame = (id) => {
  return HttpService.Delete("/game/" + id);
};

export const deleteProduct = (id) => {
  return HttpService.Delete("/product/" + id);
};

export const deletePlan = (id) => {
  return HttpService.Delete("/plan/" + id);
};

export const patchHwid = (hwid) => {
  return HttpService.Patch("/user/hwid", {
    hwid,
  });
};
