<template>
  <div class="h-full">
    <Header />
    <div class="top-[44px]" style="height: calc(100% - 88px)">
      <Sidebar />
      <div
        class="flex-1 p-4 sm:p-8 transition-all h-full"
        :class="sidenav ? 'sm:ml-[260px]' : ''"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { getUserAsync } from "@/services/UserService";
import Header from "@/layout/Header";
import Sidebar from "@/layout/Sidebar";

export default {
  name: "Layout",
  components: { Sidebar, Header },
  async created() {
    this.$store.state.user = await getUserAsync();
  },
  computed: {
    sidenav() {
      return Boolean(this.$store.state.sidenav);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .vs-loading {
    z-index: 888;
  }
  .vs-sidebar-content .vs-sidebar__header {
    padding: 0;
  }
}
</style>
