<template>
  <vs-navbar text-white square shadow class="z-1001 sticky">
    <template #right>
      <vs-button icon @click="toDiscordLink()">
        <i class="bx bxl-discord-alt"></i>
      </vs-button>
    </template>
    <template #left>
      <vs-button flat icon @click="$store.commit('toggleSidenav')">
        <i class="bx bx-menu text-purple-300" />
      </vs-button>

      <router-link to="//market">
        <div class="flex flex-row items-center">
          <img src="../assets/logo.png" height="36" width="36" />
          <span class="text-xl font-semibold">Cheesing.net</span>
        </div>
      </router-link>
    </template>
  </vs-navbar>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    discordLink: "https://discord.gg/JygBFC9eTp",
  }),
  methods: {
    toDiscordLink() {
      window.open(this.discordLink, "_blank");
    },
  },
};
</script>
