import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      cdnLink: "https://cdn.cheesing.net",
      user: {},
      version: 0,
      sidenav: true,
      medias: [],
      deletedMedias: [],
      wallets: [],
      videoActive: false,
      productDescription: "",
      mediaTypes: {
        executable: 0,
        image: 1,
        cover: 2,
        video: 3,
      },
      productStatus: {
        "In Development": 3,
        Working: 2,
        Updating: 1,
      },
    };
  },
  mutations: {
    toggleSidenav(state) {
      state.sidenav = !state.sidenav;
    },
    setWallets(state, wallets) {
      state.wallets = wallets;
    },
    setVideoActive(state, value) {
      state.videoActive = value;
    },
  },
});
