import store from "../store";
import { getMe } from "@/services/ApiService";

let askedUser = false;

export const syncUser = () => {
  askedUser = true;
  getMe().then((response) => {
    store.state.user = response;
  });
};

export const getUserAsync = async () => {
  if (!askedUser) syncUser();
  // eslint-disable-next-line no-async-promise-executor
  return await new Promise(async (resolve) => {
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    while (!store.state.user) {
      await sleep(100);
    }
    resolve(store.state.user);
  });
};
