import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/user/Login";
import Layout from "@/layout/Layout";
import token from "@/services/TokenService";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/user/Register"),
  },
  {
    path: "/",
    name: "layout",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          requiredRole: 0,
          icon: "bx bx-home",
          sidebar: true,
        },
        component: () => import("@/views/user/Home.vue"),
      },
      {
        path: "/subscriptions",
        name: "subscriptions",
        meta: {
          requiredRole: 0,
          icon: "bx bx-book-open",
          sidebar: true,
        },
        component: () => import("@/views/user/Subscriptions"),
      },
      {
        path: "/wallet",
        name: "wallet",
        meta: {
          requiredRole: 0,
          icon: "bx bx-wallet-alt",
          sidebar: true,
        },
        component: () => import("@/views/user/wallet/Wallet.vue"),
      },
      {
        path: "/game/:id",
        name: "game",
        meta: {
          requiredRole: 0,
          sidebar: false,
        },
        component: () => import("@/views/user/game/Game.vue"),
      },

      // SELLER ROUTES

      {
        path: "/store",
        name: "store",
        meta: {
          requiredRole: 10,
          icon: "bx bx-store",
          sidebar: true,
        },
        component: () => import("@/views/seller/Store.vue"),
      },
      {
        path: "/store/createPlan",
        name: "createPlan",
        meta: {
          requiredRole: 10,
          icon: "bx bx-store",
          sidebar: false,
        },
        component: () => import("@/views/seller/plans/CreatePlan.vue"),
      },
      {
        path: "/products",
        name: "products",
        meta: {
          requiredRole: 10,
          sidebar: true,
          icon: "bx bx-list-ul",
        },
        component: () => import("@/views/seller/products/Products.vue"),
      },
      {
        path: "/products/createProduct",
        name: "createProduct",
        meta: {
          requiredRole: 10,
          sidebar: false,
        },
        component: () =>
          import("@/views/seller/products/create-product/CreateProduct.vue"),
      },
      {
        path: "/plans",
        name: "plans",
        meta: {
          requiredRole: 10,
          icon: "bx bx-time",
          sidebar: true,
        },
        component: () => import("@/views/seller/plans/Plans.vue"),
      },

      // ADMIN ROUTES
      {
        path: "/wallets",
        name: "wallets",
        meta: {
          sidebar: true,
          requiredRole: 20,
          icon: "bx bx-wallet-alt",
        },
        component: () => import("@/views/admin/wallet/Wallet.vue"),
      },
      {
        path: "/transactions",
        name: "transactions",
        meta: {
          sidebar: true,
          requiredRole: 20,
          icon: "bx bx-notepad",
        },
        component: () => import("@/views/admin/Transactions.vue"),
      },
      {
        path: "/products-control",
        name: "products-Control",
        meta: {
          sidebar: true,
          requiredRole: 20,
          icon: "bx bx-category",
        },
        component: () => import("@/views/admin/product/Product.vue"),
      },
      {
        path: "/users",
        name: "users",
        meta: {
          sidebar: true,
          requiredRole: 20,
          icon: "bx bx-user",
        },
        component: () => import("@/views/admin/users/Users.vue"),
      },
      {
        path: "/plans-control",
        name: "plans-Control",
        meta: {
          sidebar: true,
          requiredRole: 20,
          icon: "bx bx-category",
        },
        component: () => import("@/views/admin/plan/Plans.vue"),
      },
      {
        path: "/plans-update",
        name: "plans-update",
        meta: {
          sidebar: false,
          requiredRole: 20,
          icon: "bx bx-category",
        },
        component: () => import("@/views/admin/plan/UpdatePlan.vue"),
      },
      {
        path: "/product/updateProduct",
        name: "updateProduct",
        meta: {
          sidebar: false,
          requiredRole: 20,
          icon: "bx bx-category",
        },
        component: () => import("@/views/admin/product/UpdateProduct.vue"),
      },
      {
        path: "/games",
        name: "Games",
        meta: {
          sidebar: true,
          requiredRole: 20,
          icon: "bx bx-game",
        },
        component: () => import("@/views/admin/game/Game.vue"),
      },
      {
        path: "/admin-subscriptions",
        name: "admin-Subscriptions",
        meta: {
          sidebar: true,
          requiredRole: 20,
          icon: "bx bx-book-alt",
        },
        component: () => import("@/views/admin/Subscriptions.vue"),
      },
      {
        path: "/game-create",
        name: "createGame",
        meta: {
          sidebar: false,
          requiredRole: 20,
          icon: "bx bx-game",
        },
        component: () => import("@/views/admin/game/CreateGame.vue"),
      },
      {
        path: "*",
        redirect: "/login",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const authToken = token.getToken();
  if (to.name !== "login" && to.name !== "register" && !authToken)
    next({ name: "login" });
  else if ((to.name === "login" || to.name === "register") && authToken)
    next({ name: "home" });
  else next();
  next();
});

// router.beforeEach((to, from, next) => {
//   const jwt = token.getToken();
//   if (jwt) {
//     const parsedJwt = token.parseJwt(jwt);
//     if (!(parsedJwt.role >= to.meta.requiredRole)) {
//       next({ name: "market" });
//     }
//   }
//   next();
// });

export default router;
