<template>
  <vs-sidebar
    text-white
    square
    :open="sidenav"
    :absolute="false"
    color="primary"
    v-model="active"
    class="top-[44px] z-1000 min-w-screen sm:min-w-0"
  >
    <template #header>
      <div class="flex flex-row items-center justify-between w-full my-8 mx-4">
        <span class="font-semibold">
          {{ user && user.username }}
        </span>
        <span
          class="text-gray-400 font-bold flex flex-row items-center justify-center gap-1"
        >
          <i class="bx bxs-wallet-alt pt-[2px]"></i>
          {{ user && user.balance }}$
        </span>
      </div>
    </template>

    <vs-sidebar-item v-for="item in routes[0]" :key="item.id" :id="item.name">
      <template #icon>
        <i :class="item.meta.icon"></i>
      </template>
      <span>{{ item.name.charAt(0).toUpperCase() + item.name.slice(1) }}</span>
    </vs-sidebar-item>

    <vs-sidebar-group v-if="routes[10].length">
      <template #header>
        <vs-sidebar-item arrow>
          <template #icon>
            <i class="bx bx-store"></i>
          </template>
          Seller Panel
        </vs-sidebar-item>
      </template>
      <vs-sidebar-item
        v-for="item in routes[10]"
        :key="item.id"
        :id="item.name"
      >
        <template #icon>
          <i :class="item.meta.icon"></i>
        </template>
        <span>{{
          item.name.charAt(0).toUpperCase() + item.name.slice(1)
        }}</span>
      </vs-sidebar-item>
    </vs-sidebar-group>

    <vs-sidebar-group v-if="routes[20].length">
      <template #header>
        <vs-sidebar-item arrow>
          <template #icon>
            <i class="bx bx-user-circle"></i>
          </template>
          Admin Panel
        </vs-sidebar-item>
      </template>
      <vs-sidebar-item
        v-for="item in routes[20]"
        :key="item.id"
        :id="item.name"
      >
        <template #icon>
          <i :class="item.meta.icon"></i>
        </template>
        <span>{{
          item.name.charAt(0).toUpperCase() + item.name.slice(1)
        }}</span>
      </vs-sidebar-item>
    </vs-sidebar-group>

    <template #footer>
      <div class="flex justify-end w-full mb-12">
        <vs-button flat to="/logout" icon @click="logout">
          <i class="bx bx-log-out text-purple-300" />
        </vs-button>
      </div>
    </template>
  </vs-sidebar>
</template>

<script>
import tokenService from "@/services/TokenService";
import token from "@/services/TokenService";

export default {
  name: "Sidebar",
  data() {
    return {
      active: this.$route.name,
    };
  },
  methods: {
    logout() {
      tokenService.clearToken();
      this.$router.push("/login");
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    sidenav() {
      return Boolean(this.$store.state.sidenav);
    },
    routes() {
      const items = [];
      this.$router.options.routes.forEach((route) => {
        items.push({
          name: route.name,
          path: route.path,
          children: route.children,
        });
      });
      const layout = items.find((i) => i.name === "layout");
      const jwt = token.getToken();
      if (jwt) {
        const parsedJwt = token.parseJwt(jwt);
        const role = parsedJwt.role;
        const routes = {
          0: [],
          10: [],
          20: [],
        };
        layout.children.forEach((i) => {
          if (i.meta && i.meta.sidebar && role >= i.meta.requiredRole)
            routes[i.meta.requiredRole].push(i);
        });
        return routes;
      }
      return [];
    },
  },
  watch: {
    active: {
      handler() {
        this.$router.push(`/${this.active}`);
      },
      immediate: false,
    },
  },
  created() {
    if (window.innerWidth < 768) {
      this.$store.commit("toggleSidenav");
    }
  },
};
</script>

<style scoped>

</style>
